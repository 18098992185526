/**
 * API Documentation
 * API Documentation
 *
 * The version of the OpenAPI document: uk-cca-app-api 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SubsistenceFeesMoaSearchResultInfoDTO {
  moaId?: number;
  transactionId?: string;
  businessId?: string;
  name?: string;
  paymentStatus?: 'AWAITING_PAYMENT' | 'PAID' | 'CANCELLED' | 'OVERPAID';
  markFacilitiesStatus?: 'IN_PROGRESS' | 'COMPLETED' | 'CANCELLED';
  currentTotalAmount?: string;
  outstandingTotalAmount?: string;
  submissionDate?: string;
}
